export const EXTENDED_FIELDS = "ExtendedFields";
export const CREATE_ADDON_GIFT = "CreateAddOnGift";
export const CTA_EDITOR = "CtaEditor";
export const ALERT_EDITOR = "AlertEditor";
export const STAX_KYC = "StaxKYC";
export const STAX_STATUS = "StaxStatus";
export const GIFT_REFUND = "GiftRefund";
export const FNC_UPGRADE = "FNCUpgrade";
export const SACRAMENT_HISTORY = "SacramentHistory";
export const SACRAMENT_TRACKING = "SacramentTracking";
export const TWO_FA_ENROLLMENT = "twofaEnrollment";
export const TWO_FA_DISENROLLMENT = "twofaDisenrollment";
export const MANAGE_PROXY_CONTACTS = "manageProxyContacts";
export const FAMILY_RELATIONSHIP_PICKER = "familyRelationshipPicker";
export const EXTENDED_FIELD_HISTORY = "extendedFieldHistory";
export const ASK_THE_MEMBER = "askTheMember";
export const CONTACT_HISTORY = "contactHistory";
export const DONOR_SUMMARY_REPORTS = "donorSummaryReports";
export const CREATE_GROUP = "CreateGroup";
export const FNC_SMART_GROUP_PROMO = "FncSmartGroupPromo";
export const HOUSEHOLD_DB_VIDEO = "HouseholdDBVideo";
export const FAMILY_REGISTRATION_SPLASH = "FamilyRegistrationSplash";
export const GIVING_SPLASH = "GivingSplash";
export const CAMPAIGNS_SPLASH = "CampaignsSplash";
export const MANAGE_MY_GIVING_SPLASH = "ManageMyGivingSplash";
export const FNC_SMART_GROUP_UPGRADE_GROUP_LIMIT =
  "SmartGroupUpgradeGroupLimit";
export const LEGAL_ENTITY_INFO = "LegalEntityInfo";
